/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();

                // Fastclick
                FastClick.attach(document.body);

                // Remove empty p tags
                $('p:empty').remove();

                // External links
                $('a').filter(function(){
                    return this.hostname && this.hostname !== location.hostname;
                }).addClass('external').attr('target', '_blank');

                //*Mobile Menu*//
                $( ".c-header__toggle" ).click(function(e) {
                    e.preventDefault();

                    $('.m-menu').animate({height: 'toggle'});
                });




                // for the mobile animation

                $(".c-header__toggle").click(function(){
                    $(this).toggleClass("is-active");
                });

                ////// Pop Up Activate

                $(".app-revealer-1").click(function(){
                    $.fancybox.open({
                        src: '.app-1',
                        type: 'inline'
                    });
                });

                $(".app-revealer-2").click(function(){
                    $.fancybox.open({
                        src: '.app-2',
                        type: 'inline'
                    });
                });

                $(".app-revealer-3").click(function(){
                    $.fancybox.open({
                        src: '.app-3',
                        type: 'inline'
                    });
                });

                //// Check for cookie on pop up

                //    if (!Cookies.get('dinner_meetings')) {
                ///        setTimeout(function () {
                ///            $.fancybox.open({
                ///                src: '.popup-overlay',
                ///                type: 'inline'
                ///            });
                ///
                ///            Cookies.set('dinner_meetings', 'true', { expires: 1 });
                ///        }, 500);
                ///    }


                // click to reveal action

                $(".h-form").click(function(){
                    $(".reveal-me").toggleClass("reveal");
                });

                $(".close").click(function(){
                    $(".reveal-me").toggleClass("reveal");
                });
                //Faqs Drop Down Control//
                $(function () {
                    var faqs = $('.c-faq__control').filter(function () { return $(this).nextAll().length > 0 ;});
                    $('<span class="faq-drop"><p><i class="fas fa-angle-down"></i></p></span>').insertAfter(faqs);
                    $('.faq-drop').click(function (e) {
                        $(this).next().slideToggle(300);
                        return false;
                    });

                });



                ////// For Sale Topic Gallery


                if ($('.js-carousel-gallery').length) {
                    $('.js-carousel-gallery').slick({
                        arrows: false,
                        asNavFor: '.js-carousel-nav',
                        dots: false,
                        fade: true,
                        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-right"></i></button>',
                        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-left"></i></button>',
                        rows: 0,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    });
                }

                if ($('.js-carousel-nav').length) {
                    $('.js-carousel-nav').slick({
                        arrows: false,
                        asNavFor: '.js-carousel-gallery',
                        autoplay: true,
                        dots: false,
                        focusOnSelect: true,
                        rows: 0,
                        slidesToShow: 6,
                        slidesToScroll: 6
                    });
                }

                (function ($) {

                    if ($('.c-carousel--hero').length) {
                        $('.c-carousel--hero').slick({
                            accessibility: true,
                            adaptiveHeight: true,
                            autoplay: true,
                            autoplaySpeed: 50000,
                            arrows: false,
                            fade: true,
                            pauseOnFocus: false,
                            pauseOnHover: false,
                            speed: 1000,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false,

                        });
                    }
                })(jQuery);

                //////////////  Events



                (function ($) {

                    if ($('.c-carousel--events').length) {
                        $('.c-carousel--events').slick({
                            accessibility: true,
                            adaptiveHeight: true,
                            autoplay: true,
                            autoplaySpeed: 50000,
                            arrows: false,
                            fade: true,
                            pauseOnFocus: false,
                            pauseOnHover: false,
                            speed: 1000,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            dots: false,

                        });
                    }
                })(jQuery);

                //////////////////////// Blog News

                (function ($) {

                    $('.article_slider').slick({
                        autoplay: true,
                        autoplaySpeed: 50000,
                        arrows: false,
                        pauseOnFocus: false,
                        pauseOnHover: false,
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        dots: false,

                        responsive: [
                            {
                                breakpoint: 1200,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 3,
                                    infinite: true,
                                    dots: true
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 3,
                                    infinite: true,
                                    dots: true
                                }
                            },
                        ],
                    });
                })(jQuery);






                //Add and removes Class for Arrow rotation
                $(function() {                       //run when the DOM is ready
                    $(".faq-drop").click(function() {  //use a class, since your ID gets mangled
                        if ($(this).hasClass("faq-up")) {
                            $(this).removeClass("faq-up");
                        }
                        else
                        {
                            $(this).addClass("faq-up");
                        }
                    });


                    $(".officer").click(function() {  //use a class, since your ID gets mangled
                        if ($(this).hasClass("officer-reveal")) {
                            $(this).removeClass("officer-reveal");
                        }
                        else
                        {
                            $(this).addClass("officer-reveal");
                        }
                    });
                });






                // Mobile Drop Menu Primary Nav
                $(function () {
                    var children = $('.m-nav > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
                    $('<span class="drop-menu"><i class="fas fa-chevron-down"></i></span>').insertAfter(children);
                    $('.m-nav .drop-menu').click(function (e) {
                        $(this).next().slideToggle(300);
                        return false;
                    });
                });

                // Apply place holder text to login wp_login_form

                jQuery(document).ready(function(){
                    jQuery('#user_login').attr('placeholder', 'username');
                    jQuery('#user_email').attr('placeholder', 'User Email');
                    jQuery('#user_pass').attr('placeholder', 'password');
                });


            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired

                if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                    // tasks to do if it is a Mobile Device
                    $(function () {
                        var children = $('.b-nav > li > a').filter(function () { return $(this).nextAll().length > 0 ;});
                        $('<span class="drop-menu"><i class="fas fa-chevron-down"></i></span>').insertAfter(children);
                        $('.b-nav .drop-menu').click(function (e) {
                            $(this).next().slideToggle(300);
                            return false;
                        });
                    });

                }

            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
